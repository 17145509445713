import React, { lazy, Suspense, useState, useMemo, useEffect } from 'react';
import {
    Admin,
    CustomRoutes,
    nanoDarkTheme,
    Loading,
    AdminContext,
    AdminUI,
    defaultI18nProvider,
    useTheme
} from "react-admin";
import { Route } from "react-router-dom"
import { useParams, useSearchParams } from 'react-router-dom';
import { dataProvider } from '@switch/widget';
import { useSwitchTheme, SwitchThemeContextProvider } from '@switch/core'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import english from "./locales/en/translation.json";
import debug from "./locales/debug/translation.json";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enGB } from 'date-fns/locale'

const WidgetBase = lazy(() => import("@switch/widget").then(module => ({ default: module.WidgetBase })));
//const dataProvider = lazy(() => import("@switch/widget").then(module => ({ default: module.dataProvider })));
const Layout = lazy(() => import("@switch/widget").then(module => ({ default: module.Layout })));

export const WidgetLoader = () => {
    return (
        <Suspense fallback={<Loading />}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
                <SwitchThemeContextProvider>
                    <ThemeWrapper />
                </SwitchThemeContextProvider>
            </LocalizationProvider>
        </Suspense>
    );
}

const ThemeWrapper = () => {
    const { theme } = useSwitchTheme()

    const params = useParams();
    const urlParams = Object.values(params)[0]
    const widgetId = urlParams.split("/")?.[0]
    const dateProviderInstance = dataProvider(widgetId)

    const [translations, setTranslations] = useState({
        english: {
            ...englishMessages,
            ...english
        },
        debug: {
            ...englishMessages,
            ...debug
        }
    })

    const languageList = [
        { locale: 'en', name: 'English' },
        { locale: 'debug', name: 'Debug' },
    ]

    const i18nProvider = useMemo(() => polyglotI18nProvider(
        locale => {
            if (locale === 'debug') return translations?.debug;
            return translations?.english;
        },
        "en",
        languageList
    ), [translations]);


    return (
        <AdminContext
            basename="/widget"
            dataProvider={dateProviderInstance}
            theme={theme?.light}
            darkTheme={theme?.dark}
            i18nProvider={i18nProvider}
        >
            <AdminUI
                layout={Layout}
            >
                <CustomRoutes>
                    <Route path="/:widgetId/*" element={<WidgetBase widgetId={widgetId} />} />
                </CustomRoutes>
            </AdminUI>
        </AdminContext>
    )
}